











import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ErrorPage',
  props: {
    error: {
      type: Object,
      required: true,
    },
  }
});
