import { Middleware } from '@nuxt/types';
import { SOLEPRO_STORE_CODE, BRIGHTCOVE_MERCH_ID } from '~/utils/constants/store';


const middleware : Middleware = (context) => {
  const scriptsEnvPrefix = context.$config.soleEnv !== 'prod' ? 'qa' : 'prod';

  /*if (context.i18n.locale == SOLEPRO_STORE_CODE) {

    if (typeof window !== "undefined" && !(window as any).utag) {
      const scriptSync = document.createElement('script')
      scriptSync.src = `//tags.tiqcdn.com/utag/ilsole24ore/24pro/${scriptsEnvPrefix}/utag.sync.js`
      document.head.appendChild(scriptSync)

      const scriptBody = document.createElement('script')
      scriptBody.src = `/resources/scripts/trackings/24pro/utag-${scriptsEnvPrefix}.js`
      document.head.appendChild(scriptBody)
    }

  } else {

    if (typeof window !== "undefined" && !(window as any).utag) {
      const scriptSync = document.createElement('script')
      scriptSync.src = `//tags.tiqcdn.com/utag/ilsole24ore/shopping/${scriptsEnvPrefix}/utag.sync.js`
      document.head.appendChild(scriptSync)

      const scriptBody = document.createElement('script')
      scriptBody.src = `/resources/scripts/trackings/sh24/utag-${scriptsEnvPrefix}.js`
      document.head.appendChild(scriptBody)
    }
  }*/

};

export default middleware;
