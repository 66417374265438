export const ISTITUTIONAL_SITE = 'https://www.ilsole24ore.com/';
export const MBOOKS_STORE_CODE = 'mbooks';
export const SOLEPRO_STORE_CODE = '24orepro';
export const SHOPPING24_STORE_CODE = 'shopping24';
export const DEFAULT_STORE_CODE = 'paywall';
export const PAYWALL_STORE_CODE = 'paywall';
export const MBOOKS_LAYOUT = 'mbooks';
export const SHOPPING24_LAYOUT = 'default';
export const RINNOVI_LAYOUT = 'default';
export const DEFAULT_LAYOUT = 'default';
export const SOLEPRO_LAYOUT = '24orepro';
export const LAYOUTS = {
  rinnovi: RINNOVI_LAYOUT,
  mbooks: MBOOKS_LAYOUT,
  default: DEFAULT_LAYOUT,
  "24orepro": SOLEPRO_LAYOUT,
};
export const BRIGHTCOVE_MERCH_ID = "1231248584001"